<template>
  <div>
    <custom-table
      ref="customTable"
      :columns="columns"
      :footer-actions="footerActions"
      :is-page-show="true"
      api-url="/license/v1/assign-list"
      table-height="calc(100vh - 215px)"
      @showDialogCreate="showDialogCreate"
      @cancelAssign="cancelAssign"
      @renewalAssign="renewalAssign"
    ></custom-table>

    <v-dialog v-model="createDialog" max-width="480px">
      <AssignCreate
        v-if="createDialog"
        :item-detail="itemDetail"
        @cancel="hideDialogCreate"
        @refreshData="refreshCustomTable"
      />
    </v-dialog>

    <v-dialog v-model="permissionDialog" max-width="1200px">
      <PricePermission
        v-if="permissionDialog"
        :item-detail="itemDetail"
        @cancel="hideDialogPermission"
        @refreshData="refreshCustomTable"
      />
    </v-dialog>
  </div>
</template>

<script>
import {
  LICENSE_ASSIGN_STATUS_OPTIONS,
  LICENSE_PRICE_UNIT_OPTIONS,
  LICENSE_PRICE_TYPE_OPTIONS,
  LICENSE_PRICE_CLASSIFY_OPTIONS,
  VAT_OPTIONS,
  LICENSE_UNIT_LIMITS,
  LICENSE_UNIT_LIMIT_DETAIL,
} from "@/libs/const";
import { httpClient } from "@/libs/http";
import moment from "moment";
export default {
  components: {
    AssignCreate: () => import("@/components/license/AssignCreate"),
    PricePermission: () => import("@/components/license/PricePermission"),
  },
  name: "Assign",
  data: () => ({
    columns: [],
    footerActions: [],
    sums: [],
    isLoading: false,
    createDialog: false,
    editDialog: false,
    permissionDialog: false,
    priceDialog: false,
    itemDetail: {},
    statusOptions: [...LICENSE_ASSIGN_STATUS_OPTIONS],
    typeOptions: [...LICENSE_PRICE_TYPE_OPTIONS],
    classifyOptions: [...LICENSE_PRICE_CLASSIFY_OPTIONS],
    unitOptions: [...LICENSE_PRICE_UNIT_OPTIONS],
    vatOptions: [...VAT_OPTIONS],
    unitLimits: [...LICENSE_UNIT_LIMITS],
    unitLimitDetail: { ...LICENSE_UNIT_LIMIT_DETAIL },
  }),
  created() {
    const columns = [
      {
        type: "date-range-filter",
        label: this.$t("labels.date_create"),
        placeholder: this.$t("labels.date_create"),
        name: "created_at",
        hasSort: false,
        sortName: "created_at",
        key: "created_at",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.identity_create"),
        placeholder: this.$t("labels.identity_create"),
        name: "identity_name",
        hasSort: false,
        sortName: "identity_name",
        key: "identity_name",
        required: true,
      },
      {
        type: "select-country",
        label: this.$t("labels.country"),
        placeholder: this.$t("labels.country"),
        name: "id_country",
        hasSort: false,
        sortName: "country_name",
        key: "country_name",
        required: true,
        class: "c-input-small",
      },
      {
        type: "select-filter",
        label: this.$t("labels.classify"),
        placeholder: this.$t("labels.classify"),
        name: "classify",
        hasSort: false,
        sortName: "classify",
        key: "classify",
        options: this.classifyOptions,
        required: true,
      },
      {
        type: "select-filter",
        label: this.$t("labels.administer"),
        placeholder: this.$t("labels.administer"),
        name: "type",
        hasSort: false,
        sortName: "type",
        key: "type",
        options: this.typeOptions,
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.object"),
        placeholder: this.$t("labels.object"),
        name: "object_name",
        hasSort: false,
        sortName: "object_name",
        key: "object_name",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.function"),
        placeholder: this.$t("labels.function"),
        name: "name",
        hasSort: false,
        sortName: "name",
        key: "name",
        required: true,
      },
      {
        type: "select-filter",
        label: this.$t("labels.unit"),
        placeholder: this.$t("labels.unit"),
        name: "unit",
        hasSort: false,
        sortName: "unit",
        key: "unit",
        options: this.unitOptions,
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.price"),
        placeholder: this.$t("labels.price"),
        name: "price",
        hasSort: false,
        sortName: "price",
        key: "price",
        required: true,
      },
      {
        type: "select-filter",
        label: this.$t("labels.vat"),
        placeholder: this.$t("labels.vat"),
        name: "vat",
        hasSort: false,
        sortName: "vat",
        key: "vat",
        options: this.vatOptions,
        required: true,
      },
      {
        type: "select-filter",
        label: this.$t("labels.status"),
        placeholder: this.$t("labels.status"),
        name: "status",
        hasSort: false,
        sortName: "status",
        key: "status",
        options: this.statusOptions,
        required: true,
      },
      {
        type: "date-range-filter",
        label: this.$t("labels.started_date"),
        placeholder: this.$t("labels.started_date"),
        name: "start_time",
        hasSort: false,
        sortName: "start_time",
        key: "start_time",
        required: true,
      },
      {
        type: "date-range-filter",
        label: this.$t("labels.expired_date"),
        placeholder: this.$t("labels.expired_date"),
        name: "end_time",
        hasSort: false,
        sortName: "end_time",
        key: "end_time",
        required: true,
      },
      {
        type: "date-range-filter",
        label: this.$t("labels.end"),
        placeholder: this.$t("labels.end"),
        name: "ended_early_time",
        hasSort: false,
        sortName: "ended_early_time",
        key: "ended_early_time",
        required: true,
      },
      {
        type: "button",
        label: "",
        headerAction: "showDialogCreate",
        headerActionColor: "primary",
        headerActionLabel: this.$t("labels.add"),
        action: false,
        required: true,
        buttonActions: [
          {
            action: "renewalAssign",
            text: this.$t("labels.renewal"),
            color: "success",
            class: "mb-1 v-btn--block",
            permissions: [],
            modules: [],
            allowKey: "can_renew",
          },
          {
            action: "cancelAssign",
            text: this.$t("labels.cancel"),
            color: "error",
            class: "v-btn--block",
            permissions: [],
            modules: [],
            allowKey: "can_cancel",
          },
          {
            action: "cancelAssign",
            text: this.$t("labels.stop"),
            color: "error",
            class: "v-btn--block",
            permissions: [],
            modules: [],
            allowKey: "can_stop",
          },
          /* {
            action: "showDialogPrice",
            text: this.$t("labels.price_history"),
            color: "purple",
            class: "white--text v-btn--block",
            permissions: [],
            modules: [],
          }, */
        ],
      },
    ];
    this.columns = [...columns];

    const footerActions = [];
    this.footerActions = [...footerActions];

    const sums = [];
    this.sums = [...sums];
  },
  mounted() {},
  methods: {
    refreshCustomTable() {
      this.$refs.customTable.getList();
    },
    showDialogCreate() {
      this.createDialog = true;
    },
    hideDialogCreate() {
      this.itemDetail = {};
      this.createDialog = false;
    },
    async cancelAssign(item) {
      if (!confirm(this.$t("messages.are_you_sure_confirm"))) {
        return false;
      }
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/license/v1/assign-cancel", item);
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.update_success"));
        this.refreshCustomTable();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    async renewalAssign(item) {
      if (!confirm(this.$t("messages.are_you_sure_confirm"))) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      let newStartTime = null;
      let newEndTime = null;
      let isLimit = false;

      if (item.unit && this.unitLimits.includes(item.unit)) {
        const unitDetail = this.unitLimitDetail[item.unit];

        newStartTime = moment(item.end_time)
          .add(unitDetail.renew_amount, unitDetail.renew_type)
          .format("YYYY-MM-DD");

        newEndTime = moment(newStartTime)
          .add(unitDetail.add_amount, unitDetail.add_type)
          .subtract(unitDetail.sub_amount, unitDetail.sub_type)
          .format("YYYY-MM-DD");

        isLimit = true;
      }

      const data = {
        id_license: item.id_license,
        id_object: item.id_object,
        price: item.price,
        vat: item.vat,
        start_time: newStartTime,
        end_time: newEndTime,
        is_limit: isLimit,
      };

      try {
        await httpClient.post("/license/v1/assign-save", data);
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.update_success"));
        this.refreshCustomTable();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>
